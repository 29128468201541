/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
/* footer */
.footer{
  width: 100%;
  height: 180px;
  background: var(--bg1);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.footer__div{
  cursor: default;
  text-align: center;
  font-family: "pluto-cond-bold";
  color: var(--font-colour)
}

.dev__tag{
  cursor: pointer !important;
  text-decoration: none !important;
  color: var(--font-colour) !important;
}

.api__tag{
  font-family: "pluto-reg";
  color: var(--font-colour) !important;
  text-decoration: none !important;
}