/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
/* header */
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: var(--bg1);
  z-index: 999;
}

/* header  <div>*/
.header__div{
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;
}

/* brand name inside the header */
.header__brand{
  color: var(--font-colour);
  font-family: "alien-bold";
  font-size: 1.7rem;
  width: 250px;
  height: 60px;
}

.header__icon_div{
  background: var(--bg2);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  position: relative;
}

/* brand icon inside the header */
.header__icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}

/* header switch */
.header__switch_div{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}


/* Responsive Mobile */
@media(max-width: 600px){
  .header{
    height: 85px;
  }

  .header__brand{
    width: 170px;
    height: 60px;
  }

  .header__icon_div{
    width: 54px;
    height: 54px;
  }
  .header__icon{
    width: 48px;
    height: 48px;
  }
}