/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
.doc__main{
  padding-top: 130px;
}

.margin__top{
  margin-top: 50px;
}

/***************************** DOCUMENTATION HIGHLIGHT DIV *****************************/

.doc__highlight_div{
  background: var(--highlight-div);
  width: 100%;
  display: flex;
  padding-top: 70px;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: relative;
  cursor: default;
}

/***************************** DOCUMENTATION TEXT *****************************/

.doc__text{
  padding-left: 30px;
  padding-right: 30px;
  font-family: "pluto-bold";
  font-size: 0.9rem;
  color: var(--font-colour);
  margin: 0;

  width: 100%;
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: pre-wrap;
}

/***************************** DOCUMENTATION HIGHLIGHT TAGS *****************************/

.doc__text_highlight_url{
  color: var(--highlight-url);
}

.doc__text_highlight_green,
.doc__text_highlight_red{
  font-family: "pluto-bold";
  font-size: 0.9rem;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 20px;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
}

.doc__text_highlight_green{
  background: #49CC90;
}

.doc__text_highlight_red{
  background: #ff443e;
}

.doc__text_highlight_datatype{
  color: #5568BE;
  font-family: "pluto-cond-bold";
}

/***************************** DOCUMENTATION TABLE *****************************/

th{
  font-family: "pluto-bold";
}

td, th{
  padding: 10px 20px;
  border: 2px solid var(--font-colour);
  font-family: "pluto-reg";
}

td:nth-child(1){
  text-align: center;
}

td:nth-child(2){
  text-align: left;
}