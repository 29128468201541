/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
:root{
  --svg-bg: #ffffff;
  --svg-fg: #000000;
}

/* dark mode colours */
.dark__mode {
  --font-colour: #ffffff;
  --bg1: #202020;
  --bg2: #252323;
  --popup-shadow: rgba(49, 49, 49, 0.219);
  --colour-picker: #3d3d3d;
  --highlight-div: #202020;
  --highlight-url: #00ffff;
  --clear-btn: #3d3d3d;
}

/* light mode colours */
.light__mode {
  --font-colour: #000000;
  --bg1: #3cb4ff;
  --bg2: #ffffff;
  --popup-shadow: #31313148;
  --colour-picker:#ffffff;
  --highlight-div: #eeeeee;
  --highlight-url: #004bad;
  --clear-btn: #ececec;
}