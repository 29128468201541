/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
@font-face {
  font-family: "dohyeon";
  src: url("../fonts/DoHyeon/DoHyeon-Regular.ttf");
}

@font-face {
  font-family: "personalservice";
  src: url("../fonts/PersonalService/PersonalServices.ttf");
}

@font-face {
  font-family: "pluto-bold";
  src: url("../fonts/PlutoSans/PlutoSans-Bold.ttf");
}

@font-face {
  font-family: "pluto-cond-bold";
  src: url("../fonts/PlutoSans/PlutoSans-Cond-Bold.ttf");
}

@font-face {
  font-family: "pluto-cond-reg";
  src: url("../fonts/PlutoSans/PlutoSans-Cond-Regular.ttf");
}

@font-face {
  font-family: "pluto-reg";
  src: url("../fonts/PlutoSans/PlutoSans-Regular.ttf");
}