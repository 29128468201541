/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
/***************************** POPUP OVERLAY *****************************/

.popup-overlay {
  background: rgba(0, 0, 0, 0.356);
}

.popup__div {
  width: 600px;
  height: auto;
  background: var(--bg2);
  border-radius: 5px;
  position: relative;
  font-family: "pluto-reg";
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.281);
  z-index: 999;
  margin-left: auto;
  margin-right: auto;
}

/***************************** WARNING MESSAGE *****************************/

#warning__message {
  text-align: center;
  font-size: 1.5rem;
  color: rgb(255, 31, 31);
  cursor: default;
  padding: 100px 0;
}

/***************************** CLOSE BUTTON *****************************/

.popup__close_btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--font-colour);
}

.popup__close_btn:hover,
.popup__close_btn:active{
  color: rgb(245, 60, 60);
}

/***************************** QR IMAGE *****************************/
.qr__img_div {
  width: 100%;
  height: 100%;
  text-align: center;
}

.qr__img {
  margin-top: 10px;
  width: 180px;
  height: 180px;
}

.qr__img_input {
  background: var(--bg2);
  border: none;
  outline: none;
  color: var(--font-colour);
  margin-top: 30px;
  font-size: 1.2rem;
  text-align: center;
}

.qr__download_btn {
  font-family: "pluto-bold";
  display: inline-block;
  margin-top: 40px;
  padding: 10px 40px;
  background: #8aff7b;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-bottom: 50px;
}

/***************************** POPUP TEXT *****************************/

.popup__text{
  margin-top: -10px;
  font-size: 1rem;
  color: var(--font-colour);
}

.popup__text>span{
  padding: 2px 10px;
  border-radius: 8px;
  background: #8aff7b;
  color: black;
}

.text__top{
  margin-top: 50px;
}

/***************************** RESPONSIVE MOBILE *****************************/

@media (max-width: 600px) {
  .popup__div {
    width: 90vw;
    height: autoh;
    padding: 0;
  }

  #warning__message {
    margin: 10px 30px;
  }
}
