/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
/* main row and the sub row */
.main__row,
.sub__row{
  margin: 0;
  justify-content: center;
}

/* main div after the header */
.main__div{
  padding-top: 130px;
  padding-bottom: 130px;
  text-align: center;
  font-family: "pluto-reg";
  color: var(--font-colour);
}


/* main textarea within main div */
.main__textarea{
  margin-top: 40px;
  width: 100%;
  height: 200px;
  font-family: "pluto-reg";
  font-size: 1.3rem;
  border: none;
  outline: none;
  color: var(--font-colour);
  background: var(--bg2);
}

/***************************** DUMMY QR SVG *****************************/

/* The dummy_qr SVG */
.main__svg{
  width: 100%;
  height: 100%;
  background: var(--svg-bg);
  border: 1px solid black;
  border-radius: 4px;
}

/* The dummy_qr SVG <g> */
.main__svg_group{
  width: 100%;
  height: 100%;
  fill: var(--svg-fg);
}

/***************************** COLOR PICKER *****************************/

/* Twitter color picker */
.twitter-picker {
  background: var(--colour-picker) !important;
}

/* Color picker <div> */
.twitter-picker span div{
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5) !important;
}


.color__picker{
  width: 100%;
  margin-top: 50px;
}

.color__picker_col{
  margin-top: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.colour__picker_tag,
.file__tag{
  font-family: "pluto-bold";
}

.colour__picker_div{
  width: 100%;
  display: flex;
  justify-content: center;
}

#colour__selected_bg,
#colour__selected_fg{
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 7px;
  box-shadow: inset 0 0 2px var(--font-colour);
}

#colour__selected_bg{
  background-color: white;
}

#colour__selected_fg{
  background-color: black;
}

/***************************** SELECT TAGS *****************************/

.select__tag{
  margin-top: 30px;
  max-width: 400px;
  width: 70%;
  display: inline-block;
}

.react__select__control{
  background: var(--bg2) !important;
}

.react__select__single-value{
  color: var(--font-colour) !important;
}

.react__select__menu{
  background: var(--bg2) !important;
}

.css-1n7v3ny-option{
  background: transparent !important;
}

.react__select__option{
  color: var(--font-colour) !important
}


.file__res_col{
  margin-top: 50px;
}

/***************************** CLEAR BUTTON *****************************/

.clear__btn{
  font-family: "pluto-bold";
  margin-top: 50px;
  font-size: 1rem;
  border: none;
  padding: 10px 30px;
  color: var(--font-colour);
  background: var(--clear-btn);
  border-radius: 4px;
}

.clear__btn:hover,
.clear__btn:active{
  transform: scale(1.1);
}

/***************************** SUBMIT BUTTON *****************************/

.submit__btn{
  margin-top: 100px;
  font-family: "pluto-bold";
  padding: 15px 60px;
  width: auto !important;
  font-size: 1.3rem;
  background: linear-gradient(to right, #46a9fa, #8a90ff);
  border: none;
  outline: none;
  border-radius: 4px;
}

.submit__btn:hover{
  transform: scale(1.1);
  background: linear-gradient(to right, #8a90ff, #46a9fa);
}

/***************************** RESPONSIVE TABLET & MOBILE *****************************/

@media(max-width: 768px){
  .header{
    height: 80px;
  }

  .main__img{
    width: 50%;
  }

  .main__textarea{
    text-align: center;
  }

  .submit__btn_p{
    margin-top: 0;
  }

  .main__svg{
    width: 200px;
    height: 200px;
  }
}